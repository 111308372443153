body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
  margin-left: 25vw;
  margin-right: 25vw;
  margin-top: 3rem;
  margin-bottom: 4rem;
}

.center-div {
  display: flex; justify-content: center; align-items: center;
}

.right-align {
  display: flex; justify-content: right; align-items: right;
}

.margin-right {
  margin-right: 1rem;
}